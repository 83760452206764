import React from 'react';

const Status = ({red, blue, team}) => {
  console.log('Status: ', team);
  return (
    <div className='status'>
      <div className='status__info'>
        <div className='status__turn'>
          {red === 0 || blue === 0
            ? `Виграла команда ${red === 0 ? 'червоних' : 'синіх'}`
            : `Хід команди ${team === 'blue' ? 'синіх' : 'червоних'}`}
        </div>
      </div>
      <div className='status-menu'>
        <div className='status-pane red'>
          <h1 className='team-count'>{red}</h1>
        </div>
        {/*<div className='status-pane'>*/}
        {/*  <div className='status-pane__timer'>*/}
        {/*    /!*<div className={counter <= 5 ? "danger" : ""}>*!/*/}
        {/*    /!*  {secondsToTime(counter)}*!/*/}
        {/*    /!*</div>*!/*/}
        {/*    00:00*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='status-pane blue'>
          <h1 className='team-count'>{blue}</h1>
        </div>
      </div>
    </div>
  );
};

export default Status;
