import React from 'react';
import './LongClickNotification.css';

const LongClickNotification = () => {
  return (
    <div className='long-click-notification'>
      Щоб відкрити карточку
      <br />
      використовуйте довгий натиск
    </div>
  );
};

export default LongClickNotification;
