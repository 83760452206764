import React from 'react';
import './Home.css';
import Box from '../components/ThinkLogic/Box';
import { history } from '../utils/history';

const Home = () => {
  const gameSelectHandler = (event, id) => {
    switch (id) {
      case 'think-logic':
        history.push('/think-logic');
        break;
      case 'imagine-logic':
        history.push('/imagine-logic');
        break;
      case 'feel-logic':
        history.push('/feel-logic');
        break;
      default:
        history.push('/think-logic');
        break;
    }
  };

  return (
    <div className="container">
      <Box icon="fa fa-th" title="Think Logic" description="Думай логічно" onClick={(event) => gameSelectHandler(event, 'think-logic')} />
      <Box icon="fa fa-grin-wink" title="Imagine Logic" description="Уявляй логічно" onClick={(event) => gameSelectHandler(event, 'imagine-logic')} />
      <Box icon="fa fa-dice-d6" title="Feel Logic" description="Відчувай логічно" onClick={(event) => gameSelectHandler(event, 'feel-logic')} />
    </div>
  );
};

export default Home;
