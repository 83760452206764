import React from 'react';
import {useState, useEffect} from 'react';
import {history} from '../../utils/history';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AppsIcon from '@material-ui/icons/Apps';
import TimerIcon from '@material-ui/icons/Timer';

const Info = ({onModeChange, onChangeCurrentTeam, team, time, gameOver, teamWon}) => {
  const [counter, setCounter] = useState(time);
  const [prevTeam, setPrevTeam] = useState('');
  const [timerOn, setTimerOn] = useState(false);

  useEffect(() => {
    if (prevTeam !== team) {
      setCounter(time);
      setPrevTeam(team);
    }

    let timer;
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter, prevTeam, team, time]);

  const secondsToTime = (seconds) => {
    seconds = Math.round(seconds);

    const minutes = Math.floor(seconds / 60);

    const divisor_for_seconds = seconds % 60;
    const second = Math.ceil(divisor_for_seconds);

    const time = {
      m: minutes,
      s: second,
    };
    return `${time.m < 10 ? '0' + time.m : time.m}:${time.s < 10 ? '0' + time.s : time.s}`;
  };

  const showTimer = () => (
    <div className={`think-logic__timer ${counter <= 5 ? 'danger' : ''}`}>{secondsToTime(counter)}</div>
  );

  return gameOver ? (
    <div id='gameOver' style={{display: 'block'}} className='black'>
      Команда {team === 'blue' ? 'синіх' : 'червоних'} відкрила чорну картку. Виграш команди:{' '}
      {teamWon === 'blue' ? 'синіх' : 'червоних'}
    </div>
  ) : (
    <div className='think-logic__header'>
      <div className='think-logic__container'>
        <div className='think-logic__title'>Думай логічно</div>
      </div>

      {timerOn && showTimer()}

      <div className='think-logic__container'>
        <div className='think-logic__commands'>
          <div className='think-logic__item'>
            <div className='think-logic__text' onClick={onModeChange}>
              <AppsIcon /> Змінити режим
            </div>
          </div>
          <div className='think-logic__item'>
            <div
              className='think-logic__text'
              onClick={() => {
                setCounter(time);
                setTimerOn(!timerOn);
              }}>
              <TimerIcon /> Таймер {timerOn ? '(вкл.)' : '(викл.)'}
            </div>
          </div>
          <div className='think-logic__item' onClick={onChangeCurrentTeam}>
            <div className='think-logic__text'>
              <NavigateNextIcon /> Передати хід
            </div>
          </div>
          <div className='think-logic__item' onClick={() => history.push('/think-logic')}>
            <div className='think-logic__text'>
              <ExitToAppIcon /> Закінчити
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
