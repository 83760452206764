import React, {useCallback} from 'react';
import cn from 'classnames';
import {useLongPress, LongPressDetectEvents} from 'use-long-press';

const Card = ({card, mode, openCard, onSingleClick}) => {
  const callback = useCallback((event) => {
    openCard(event);
  }, []);

  const bind = useLongPress(callback, {
    onCancel: () => onSingleClick(true),
    onMove: () => onSingleClick(false),
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  const captain = mode === 'captain';

  const cardClassName = cn({
    'card-box': true,
    red: card.team === 'red' && (card.clicked || captain),
    blue: card.team === 'blue' && (card.clicked || captain),
    neutral: card.team === '' && (card.clicked || captain),
    black: card.team === 'black' && (card.clicked || captain),
    opened: card.clicked && captain,
  });

  return (
    <div className='word-card'>
      <div className={cardClassName} {...bind}>
        <span>{card.word}</span>
      </div>
    </div>
  );
};

export default Card;
