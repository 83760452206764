import React from 'react';
import Game from './views/ThinkLogic/Game';
import { Router, Route, Switch } from 'react-router-dom';
import Home from './views/Home';
import { history } from './utils/history';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const ThinkLogic = React.lazy(() => import('./views/ThinkLogic'));
const ImagineLogic = React.lazy(() => import('./views/ImagineLogic'));
const FeelLogic = React.lazy(() => import('./views/FeelLogic'));

const App = () => {
  return (
    <Router history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/think-logic" exact name="Think Logic" render={(props) => <ThinkLogic {...props} />} />
          <Route path="/imagine-logic" exact name="Imagine Logic" render={(props) => <ImagineLogic {...props} />} />
          <Route path="/feel-logic" exact name="Feel Logic" render={(props) => <FeelLogic {...props} />} />
          <Route path="/game/:id" name="Game" render={(props) => <Game {...props} />} />
          <Route path="/" name="Home" render={(props) => <Home {...props} />} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
