import React, {useCallback, useEffect, useState} from 'react';
import {useHttp} from '../../hooks/http.hook';
import Board from '../../components/ThinkLogic/Board';
import Info from '../../components/ThinkLogic/Info';
import Status from '../../components/ThinkLogic/Status';
import {io} from 'socket.io-client';
import * as _ from 'lodash';
import './Game.css';
import LongClickNotification from '../../components/LongClickNotification/LongClickNotification';

let socket = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  socket = io(`http://localhost:5000`);
} else {
  socket = io();
}

const Game = ({match}) => {
  const {loading, error, request} = useHttp();

  const [game, setGame] = useState(null);
  const [mode, setMode] = useState('');
  const [cards, setCards] = useState([]);
  const [currentTeam, setCurrentTeam] = useState();
  const [time, setTime] = useState(180);
  const [gameOver, setGameOver] = useState(false);
  const [teamWon, setTeamWon] = useState('');
  const [id, setId] = useState('');
  const [longClickMessageVisible, setLongClickMessageVisible] = useState(false);

  const getGame = useCallback(async () => {
    try {
      const response = await request(`/api/game/${match.params.id}`, 'GET', null);
      setGame(response);
      setCards(response.game.cards);
      setId(response._id);
      setCurrentTeam(response.game.red > response.game.blue ? 'red' : 'blue');
    } catch (e) {}
  }, [request, match.params.id]);

  const cardClick = (card) => {
    socket.emit('click', {
      id: game._id,
      card: card,
    });
  };

  useEffect(() => {
    getGame();

    if (id) {
      socket.emit('join', id);
    }
  }, [getGame, id]);

  useEffect(() => {
    socket.on('refresh', (game) => {
      if (mode === 'viewer' || mode === 'captain') {
        setGame(game);
        setCards([...game.game.cards]);
      }
    });
  }, [mode]);

  const counts = _.countBy(cards, (card) => card.team);

  const updateTeam = () => {
    console.log('UPDATE: Current Team:', currentTeam);
    setCurrentTeam(currentTeam === 'red' ? 'blue' : 'red');
  };

  const remaining = {
    red: counts.red - cards.filter((card) => card.team === 'red' && card.clicked).length,
    blue: counts.blue - cards.filter((card) => card.team === 'blue' && card.clicked).length,
  };

  const onModeChangeHandler = (event) => {
    event.preventDefault();
    setMode('');
  };

  const onChangeCurrentTeamHandler = (event) => {
    event.preventDefault();
    updateTeam();
  };

  const singlePressHandler = (visible) => {
    if (mode === 'captain' || mode === 'viewer') return;

    setLongClickMessageVisible(visible);
  };

  const openCardHandler = (event, index) => {
    event.preventDefault();
    event.persist();

    if (mode === 'captain' || mode === 'viewer') return;

    if (cards[index].clicked || gameOver || remaining.red === 0 || remaining.blue === 0) return;

    cards[index].clicked = true;

    cards[index].teamClicked = currentTeam;

    if (cards[index].team === 'black') {
      cardClick(cards[index]);
      setGameOver(true);
      setTeamWon(currentTeam === 'red' ? 'blue' : 'red');
      return;
    }

    if (cards[index].team !== cards[index].teamClicked && cards[index].team !== '') {
      updateTeam();
      setTime(180);
    }

    console.log('Current Team: ', currentTeam);
    console.log('Card: ', cards[index].teamClicked);
    if (cards[index].team !== '') setCurrentTeam(cards[index].team);

    cardClick(cards[index]);

    setCards([...cards]);
  };

  if (loading) return <div>Завантаження...</div>;

  if (error) return <div>Помилка: {error}</div>;

  if (mode === '') {
    return (
      <div className='game'>
        <div className='game__info'>
          <h1 className='game__header'>Приєднатися до гри</h1>
          <div className='game__description'>
            <p>Ви можете приєднатися до гри у різних режимах:</p>
          </div>
          <div className='game__mode' onClick={() => setMode('viewer')}>
            <div className='mode__item'>Спостерігач</div>
            <div className='mode__text'>У цьому режимі ви можете бачити ігрове поле, але не можете вибирати</div>
          </div>
          <div className='game__mode' onClick={() => setMode('player')}>
            <div className='mode__item'>Учасник</div>
            <div className='mode__text'>У цьому режимі ви можете бачити ігрове поле та вибирати картки</div>
          </div>
          <div className='game__mode' onClick={() => setMode('captain')}>
            <div className='mode__item'>Капітан</div>
            <div className='mode__text'>
              У цьому режимі ви можете бачити ігрове поле, а також бачити розфарбовані картки
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    cards && (
      <div id='playground' className='game-container'>
        <Info
          cards={cards}
          team={currentTeam}
          time={time}
          gameOver={gameOver}
          teamWon={teamWon}
          onChangeCurrentTeam={onChangeCurrentTeamHandler}
          onModeChange={onModeChangeHandler}
        />
        <Board
          cards={cards}
          onSingleClick={(visible) => singlePressHandler(visible)}
          openCard={(event, index) => openCardHandler(event, index)}
          mode={mode}
        />
        <Status red={remaining.red} blue={remaining.blue} team={currentTeam} />
        {longClickMessageVisible && <LongClickNotification />}
      </div>
    )
  );
};

export default Game;
