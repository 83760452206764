import React from 'react';

const Box = ({icon, title, description, onClick}) => {

  return (
    <div className='box' onClick={onClick}>
      <div className='icon'><i className={icon} aria-hidden='true'/></div>
      <div className='content'>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Box;
