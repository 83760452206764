import React from 'react';
import Card from './Card';

const Board = ({cards, mode, onSingleClick, openCard}) => {
  return (
    <div className='board'>
      {cards.map((card, index) => (
        <Card
          key={index}
          card={card}
          mode={mode}
          onSingleClick={(event) => onSingleClick(event)}
          openCard={(event) => openCard(event, index)}
        />
      ))}
    </div>
  );
};

export default Board;
